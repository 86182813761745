<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <!-- <el-form-item label="">
        <el-input
          v-model="formInline.name"
          placeholder="请输入任务名称"
        ></el-input>
      </el-form-item> -->
      <el-form-item>
        <!-- <el-button type="primary" @click="query">查询</el-button> -->
        <el-button type="primary" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      max-height="460"
      :header-cell-style="{ background: '#E5F0FF' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="60" />
      <el-table-column prop="account" label="账号" />
      <el-table-column prop="name" label="渠道" />
	  <el-table-column prop="mobile" label="手机号" />
	  <el-table-column prop="sum" label="剩余条数" />
      <el-table-column prop="updateUser" label="签名" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作" align="center" width="220">
        <template #default="scope">
          <el-button size="small" type="primary" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-button size="small" type="danger" @click="remove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHandler"
      :current-page="pagination.pageNo"
      :page-sizes="[10, 15, 20]"
      :page-size="pagination.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
	
	<!-- 新增/编辑用户弹框 -->
	<el-dialog v-model="isShow.changeItem" :title="(changeItem.id?'编辑':'新增')+'用户'" width="500px">
	  <el-form
	  v-if="isShow.changeItem"
	    :model="changeItem"
	    ref="addForm"
	    :rules="rules"
	    label-width="80px"
	  >
	  <!-- ，password，name，mobile，(amount，freeze，sum,createUser,updateUser,createTime) 参数 -->
	  
	    <el-form-item label="账号" prop="account">
	      <el-input
		  :disabled="changeItem.id"
	        v-model="changeItem.account"
	        placeholder="请输入用户账号"
	      > </el-input>
	    </el-form-item>
	    <el-form-item label="渠道" prop="name" v-if="changeItem.id">
	      <el-input
	        v-model="changeItem.name"
			placeholder="请输入渠道"
	      ></el-input>
	    </el-form-item>
	    <el-form-item label="手机号" prop="mobile">
	      <el-input
	        v-model="changeItem.mobile"
	    	placeholder="请输入用户手机号"
	      ></el-input>
	    </el-form-item>
		<el-form-item key="changeitem-password" v-if="!changeItem.id" label="密码" prop="password">
		  <el-input
		    v-model="changeItem.password"
			placeholder="请输入用户密码"
		  ></el-input>
		</el-form-item>
	  </el-form>
	  <template #footer>
	    <span class="dialog-footer">
	      <el-button @click="isShow.changeItem = false">取消</el-button>
	      <el-button type="primary" @click="addItem()">确定</el-button>
	    </span>
	  </template>
	</el-dialog>
  </div>
</template>

<script>
export default {
  name: "users",
  data() {
    return {
      formInline: {},
      tableData: [],
      pagination: {
        total: 0, //总条数
        pageNo: 1, // 页数
        length: 15, // 每页的条数
      }, // 分页控件
	  isShow:{
		  changeItem:false,
	  },
	  // 
	  changeItem:{
		  
	  },
	  rules:{
		  account: [{ required: true, message: "请输入账号", trigger: "blur" }],
		  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
		  name: [{ required: true, message: "请输入渠道", trigger: "blur" }],
		  mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
	  },
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取列表数据
    getTableData() {
      this.$http
        .request("getUserList", {
          page: this.pagination.pageNo,
          limit: this.pagination.length,
        })
        .then((res) => {
          let datas = res.result.data;
          datas.forEach((item) => {
            let time = new Date(item.createTime);
            item.createTime = this.$comm.dateFtt("yyyy-MM-dd hh:mm:ss", time);
          });
          this.tableData = datas;
          this.pagination.total = res.result.count;
        })
        .catch(() => {});
    },
    // 查询
    query() {},
	// 编辑用户 -弹框
    edit(row) {
		this.changeItem={...row};
		this.isShow.changeItem=true;
	},
	// 删除用户
    remove(id) {
		
		this.$confirm('你确定删除该用户?', '提示',)
		.then(()=>{
			this.$http.request("deleteU",{id:id})
			.then(()=>{
					this.$message({
					  type: "success",
					  message: "删除成功",
					});
					this.getTableData();
				})
			.catch(()=>{})
		})
		.catch(()=>{
			
		})
	},
    // 换每页条数
    sizeChangeHandler(length) {
      this.pagination.length = length;
      this.getTableData();
    },
    // 换页
    currentChangeHandler(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getTableData();
    },
	// 新增用户弹框
	add(){
		console.log("新增用户")
		this.changeItem={};
		this.isShow.changeItem=true;
	},
	// 新增用户请求
	addItem(){
		
		  this.$refs.addForm.validate((valid) => {
		    if (valid) {
				let f,u;
				if(!this.changeItem.id){
					f={
					account:this.changeItem.account,
					password:this.changeItem.password,
					name:this.changeItem.name,
					mobile:this.changeItem.mobile,
					amount:0,
					freeze:0,
					sum:0,
					}
					u="addUser"
				}else{
					f={
						id:this.changeItem.id,
						name:this.changeItem.name,
						mobile:this.changeItem.mobile,
					}
					u="updateU"
				}
				this.$http.request(u,f)
				.then(()=>{
					this.$message({
					  type: "success",
					  message: "操作成功",
					});
					this.isShow.changeItem=false;
					this.getTableData();
				})
				.catch(()=>{
					
				})
			}
		  });
	},
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 30px;
  text-align: right;
}
</style>